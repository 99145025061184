import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define your base query function
const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL });

// Define your API slice using createApi
export const configurationApi = createApi({
  reducerPath: "configurationApi",
  baseQuery,
  endpoints: (builder) => ({
    fetchConfiguration: builder.query({
      query: () => ({
        url: "configuration",
        method: "GET",
      }),
      transformResponse: (response) => response,
    }),
  }),
});

// Export the endpoint functions
export const { useFetchConfigurationQuery } = configurationApi;

export const { endpoints, reducerPath, reducer, middleware } = configurationApi;
