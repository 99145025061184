import React, { useState } from "react";
import { Row, Col, Typography, Form, Input, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  useForgetPasswordMutation,
  useResetPasswordMutation,
} from "./../features/authSlice";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    passwordResetingCode: "",
    newPassword: "",
    confirmPassword: "",
  });
  const [step, setStep] = useState(1);

  const [forgetPassword, { isLoading: isSendingCode }] =
    useForgetPasswordMutation();
  const [resetPassword, { isLoading: isResettingPassword }] =
    useResetPasswordMutation();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const openNotification = (type, message) => {
    notification[type]({
      message,
      duration: 2,
    });
  };

  const handleSendCode = async () => {
    try {
      const response = await forgetPassword({ email: formData.email }).unwrap();
      if (response.success) {
        openNotification("success", t("forgotPassword.codeSent"));
        setStep(2);
      } else {
        openNotification("error", response.message);
      }
    } catch (error) {
      openNotification("error", t("forgotPassword.codeSendFailed"));
    }
  };
  console.log({ step });
  const handleResetPassword = async () => {
    const passwordPattern =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordPattern.test(formData.password)) {
      openNotification("error", t("forgotPassword.invalidPassword"));
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      openNotification("error", t("forgotPassword.passwordsNotMatch"));
      return;
    }
    try {
      const response = await resetPassword({
        email: formData.email,
        passwordResetingCode: formData.passwordResetingCode,
        password: formData.password,
      }).unwrap();
      if (response.success) {
        openNotification("success", t("forgotPassword.passwordResetSuccess"));
        navigate("/login");
      } else {
        openNotification("error", response.message);
      }
    } catch (error) {
      openNotification("error", t("forgotPassword.passwordResetFailed"));
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={22} md={16} lg={12}>
        <div className="forgot-password-content">
          <Typography.Title level={2}>
            {t("forgotPassword.title")}
          </Typography.Title>
          {step === 1 && (
            <Form layout="vertical" onFinish={handleSendCode}>
              <Form.Item
                label={t("forgotPassword.email")}
                name="email"
                rules={[
                  {
                    required: true,
                    message: t("forgotPassword.emailRequired"),
                  },
                  { type: "email", message: t("forgotPassword.invalidEmail") },
                ]}
              >
                <Input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isSendingCode}
                >
                  {t("forgotPassword.sendCode")}
                </Button>
              </Form.Item>
            </Form>
          )}
          {step === 2 && (
            <Form layout="vertical" onFinish={handleResetPassword}>
              <Form.Item
                label={t("forgotPassword.code")}
                name="passwordResetingCode"
                rules={[
                  { required: true, message: t("forgotPassword.codeRequired") },
                ]}
              >
                <Input
                  name="passwordResetingCode"
                  value={formData.passwordResetingCode}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("forgotPassword.newPassword")}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t("forgotPassword.newPasswordRequired"),
                  },
                  {
                    pattern:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                    message: t("forgotPassword.invalidPassword"),
                  },
                ]}
                extra={t("forgotPassword.passwordHint")}
              >
                <Input.Password
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item
                label={t("forgotPassword.confirmPassword")}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: t("forgotPassword.confirmPasswordRequired"),
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue("password") === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(t("forgotPassword.passwordsNotMatch"))
                      );
                    },
                  }),
                ]}
              >
                <Input.Password
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isResettingPassword}
                >
                  {t("forgotPassword.resetPassword")}
                </Button>
              </Form.Item>
            </Form>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
