import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { handleFiles } from "./file";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.user?.token;
    if (token) {
      headers.set("token", `${token}`);
    }
    return headers;
  },
});

export const requestService = createApi({
  reducerPath: "requestService",
  baseQuery,
  tagTypes: ["Request"],
  endpoints: (builder) => ({
    createRequest: builder.mutation({
      queryFn: async (
        { files, name, email, description, message },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        try {
          const fileResponses = await handleFiles(files, message, null, name);
          const fileIds = fileResponses.files.map((file) => file.id);

          const response = await fetchWithBQ({
            url: "/service/request",
            method: "POST",
            body: {
              name,
              email,
              description,
              fileIds,
              message,
              serviceId: 2,
            },
          });

          if (response.error) {
            throw response.error;
          }

          return { data: response.data };
        } catch (error) {
          return { error };
        }
      },
      invalidatesTags: ["Request"],
    }),
    getMyRequests: builder.query({
      query: () => ({
        url: "/service/request/mine",
        method: "GET",
        params: { serviceId: 2 },
      }),
      providesTags: ["Request"],
    }),
    getOneRequest: builder.query({
      query: (requestId) => ({
        url: `/service/request/${requestId}`,
        method: "GET",
      }),
      providesTags: (result, error, requestId) =>
        result ? [{ type: "Request", id: requestId }] : [],
    }),
    getRequestStatistics: builder.query({
      query: () => ({
        url: "/service/request/mine/statistic",
        method: "GET",
      }),
      providesTags: ["RequestStatistics"],
    }),
  }),
});

export const {
  useCreateRequestMutation,
  useGetMyRequestsQuery,
  useGetOneRequestQuery,
  useGetRequestStatisticsQuery,
} = requestService;
