import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useGetOneRequestQuery } from "../features/requestSlice";
import {
  ArrowLeftOutlined,
  LeftOutlined,
  RightOutlined,
  ZoomInOutlined,
  ZoomOutOutlined,
  FilePdfOutlined,
  EyeOutlined,
  DownloadOutlined,
} from "@ant-design/icons";
import {
  Table,
  Typography,
  Tooltip,
  Spin,
  Divider,
  List,
  Card,
  Button,
  Row,
  Col,
  Modal,
} from "antd";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import { Document, Page, pdfjs } from "react-pdf";
import moment from "moment";
import "react-pdf/dist/esm/Page/TextLayer.css";
import { useFetchConfigurationQuery } from "../features/configurationsSlice";

const { Title, Paragraph } = Typography;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

function RequestDetails() {
  const { t } = useTranslation();
  const params = useParams();
  const {
    data: request,
    isLoading,
    isError,
  } = useGetOneRequestQuery(params.id);
  const [pdfFile, setPdfFile] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);
  const [resultData, setResultData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const truncate = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };
  const [usePlug, setUsePlug] = useState(true);
  const [expanded, setExpanded] = useState({});

  const toggleExpand = (id) => {
    setExpanded((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const truncateText = (text, maxLength = 150) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + "...";
    }
    return text;
  };

  const { data: config, isLoading: isLoadingConfig } =
    useFetchConfigurationQuery();

  useEffect(() => {
    if (config) {
      const usePlugConfig = config?.configurations?.find(
        (config) => config.key === "USE_PLUG"
      );

      if (usePlugConfig && usePlugConfig?.value === "false") {
        setUsePlug(false);
      }
    }
  }, [config]);

  const plug = [
    {
      modelId: "452f03bb649c78d05c431d99da6e4552.dir_Serikov_Artem",
      modelScore: "33.25",
    },
    {
      modelId: "452a43bb649368d05c431d55a6e4552.dir_Kogan Alexandra",
      modelScore: "49.36",
    },
  ];

  const columns = [
    { title: t("requestDetails.id"), dataIndex: "id" },
    { title: t("requestDetails.name"), dataIndex: "name" },
    { title: t("requestDetails.email"), dataIndex: "Email" },
    { title: t("requestDetails.status"), dataIndex: "status" },
    { title: t("requestDetails.message"), dataIndex: "message" },
    {
      title: t("requestDetails.createdAt"),
      dataIndex: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
  ];

  const handleViewPDF = (uuid) => {
    setPdfFile(`${process.env.REACT_APP_API_URL}/file/download/${uuid}`);
  };
  const handleViewResults = (data) => {
    try {
      if (data?.length) setResultData(data); // Set the data to display
    } catch (error) {
      console.log({ error });
    }
    setIsModalVisible(true); // Show the modal
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  };

  const goToPrevPage = () => {
    setPageNumber((prevPageNumber) => Math.max(prevPageNumber - 1, 1));
  };

  const goToNextPage = () => {
    setPageNumber((prevPageNumber) => Math.min(prevPageNumber + 1, numPages));
  };

  const zoomIn = () => {
    setScale((prevScale) => prevScale + 0.2);
  };

  const zoomOut = () => {
    setScale((prevScale) => Math.max(prevScale - 0.2, 0.2));
  };

  if (isLoading) return <Spin size="large" />;
  if (isError) return <div>Error fetching request details.</div>;

  return (
    <Row className="w-full" justify="center" align="middle">
      <Col className="w-full" xs={22} md={16} lg={12}>
        <div className="w-full mb-8 flex items-center">
          <Link to={`/${i18n.language}/request/mine`} className="mr-4">
            <Button type="primary" icon={<ArrowLeftOutlined />}>
              {t("requestDetails.goBack")}
            </Button>
          </Link>
          <Title level={2} className="text-2xl mb-0">
            {t("requestDetails.requestDetails")}
          </Title>
        </div>
        {request && (
          <div className="w-full">
            <Table
              columns={columns}
              dataSource={[request]}
              pagination={false}
            />
            <Divider />
            <Title level={3} className="text-xl mt-8 mb-4">
              {t("requestDetails.additionalDetails")}
            </Title>
            <Paragraph>
              <strong>{t("requestDetails.message")}</strong> {request.message}
            </Paragraph>
            <Paragraph>
              <strong>{t("requestDetails.description")}</strong>{" "}
              {request.description}
            </Paragraph>
            <Title level={3} className="text-xl mt-8 mb-4">
              {t("requestDetails.files")}
            </Title>
            <List
              grid={{ gutter: 16, column: 3 }}
              dataSource={request.files}
              renderItem={(file) => (
                <List.Item>
                  <Card
                    hoverable
                    className="w-64 mb-4"
                    cover={
                      file.mimetype.startsWith("image/") ? (
                        <img
                          className="mt-4 object-cover w-full h-48"
                          alt={file.originalname}
                          src={`${process.env.REACT_APP_API_URL}/file/download/${file.uuid}`}
                        />
                      ) : (
                        <FilePdfOutlined className="mt-4 text-4xl text-gray-500" />
                      )
                    }
                    bodyStyle={{ textAlign: "center", padding: "20px" }}
                  >
                    <div className="file-info text-gray-800">
                      <Tooltip title={file.originalname}>
                        <Card.Meta
                          title={
                            <span className="text-lg truncate">
                              {truncate(file.originalname, 15)}
                            </span>
                          }
                          className="text-lg"
                        />
                      </Tooltip>
                      <p className="text-gray-800">{`Size: ${file.size} bytes`}</p>
                      <Tooltip title={file.mimetype}>
                        <p className="text-gray-800">
                          {`Type: ${truncate(file.mimetype, 15)}`}
                        </p>
                      </Tooltip>
                      {/* Handling aiResult based on 0, null, or valid percentages */}
                      {file.aiResult !== null ? (
                        <div className="scan-info mt-4">
                          <p>
                            <span className="font-semibold">
                              {t("requestDetails.scanResult")}:{" "}
                            </span>
                            {file.aiResult === 0 ? (
                              <span className="text-red-500">0%</span>
                            ) : (
                              <span className="text-green-500">
                                {file.aiResult}%
                              </span>
                            )}
                          </p>
                          <p>
                            <span className="font-semibold">
                              {t("requestDetails.status")}:{" "}
                            </span>
                            <span className="text-green-500">
                              {t("requestDetails.scanned")}
                            </span>
                          </p>
                        </div>
                      ) : (
                        <div className="scan-info mt-4">
                          <p>
                            <span className="font-semibold">
                              {t("requestDetails.status")}:{" "}
                            </span>
                            <span className="text-red-500">
                              {t("requestDetails.notScanned")}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                    <Tooltip title={t("requestDetails.view")}>
                      <Button
                        type="link"
                        icon={<EyeOutlined />}
                        disabled={file.aiResult === null}
                        onClick={() => {
                          try {
                            handleViewResults(
                              usePlug ? plug : JSON.parse(file?.metaData)
                              //JSON.parse(file?.metaData)
                            );
                          } catch (error) {
                            console.log({ error });
                          }
                        }}
                        className="text-blue-500 hover:underline block mt-4"
                      />
                    </Tooltip>
                    {/*                     <Tooltip title={t("requestDetails.download")}>
                      <Button
                        type="link"
                        icon={<DownloadOutlined />}
                        href={`${process.env.REACT_APP_API_URL}/file/download/${file.uuid}`}
                        download
                        className="text-blue-500 hover:underline block mt-4"
                      />
                    </Tooltip> */}
                    {/* 
                    {file.report && (
                      <>
                        <Divider />
                        <span className="text-lg">
                          {t("requestDetails.report")}
                        </span>
                        <div className="mt-2">
                          <Tooltip title={t("requestDetails.viewReport")}>
                            <Button
                              type="link"
                              icon={<EyeOutlined />}
                              onClick={() => handleViewPDF(file.report.uuid)}
                              className="text-blue-500 hover:underline block mt-2"
                            />
                          </Tooltip>
                          <Tooltip title={t("requestDetails.downloadReport")}>
                            <Button
                              type="link"
                              icon={<DownloadOutlined />}
                              href={`${process.env.REACT_APP_API_URL}/file/download/${file.report.uuid}`}
                              download
                              className="text-blue-500 hover:underline block mt-2"
                            />
                          </Tooltip>
                        </div>
                      </>
                    )} */}
                  </Card>
                </List.Item>
              )}
            />
            <Modal
              title={t("requestDetails.viewResults")}
              visible={isModalVisible}
              onCancel={() => setIsModalVisible(false)}
              footer={null}
            >
              <List
                itemLayout="horizontal"
                dataSource={resultData}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      title={<span>{item.modelId}</span>}
                      description={
                        <>
                          <p>
                            {t("requestDetails.score")}: {item.modelScore}%
                          </p>
                          {item.extraInformation && (
                            <>
                              <p>
                                <strong>
                                  {t("requestDetails.additionalInformation")}:
                                </strong>{" "}
                                <Paragraph>
                                  {expanded[item.modelId]
                                    ? item.extraInformation
                                    : truncateText(item.extraInformation, 60)}
                                </Paragraph>
                              </p>
                              <Button
                                type="link"
                                onClick={() => toggleExpand(item.modelId)}
                              >
                                {expanded[item.modelId]
                                  ? t("requestDetails.showLess")
                                  : t("requestDetails.showMore")}
                              </Button>
                            </>
                          )}
                        </>
                      }
                    />
                  </List.Item>
                )}
              />
            </Modal>
            {pdfFile && (
              <div className="pdf-viewer mt-8">
                <Title level={3} className="text-xl mt-8 mb-4">
                  {t("requestDetails.pdfViewer")}
                </Title>
                <div className="pdf-controls mb-4 text-center">
                  <Button
                    icon={<LeftOutlined />}
                    onClick={goToPrevPage}
                    disabled={pageNumber <= 1}
                  />
                  <Button
                    icon={<RightOutlined />}
                    onClick={goToNextPage}
                    disabled={pageNumber >= numPages}
                  />
                  <Button
                    icon={<ZoomOutOutlined />}
                    onClick={zoomOut}
                    disabled={scale <= 0.2}
                  />
                  <Button icon={<ZoomInOutlined />} onClick={zoomIn} />
                </div>
                <Document
                  file={pdfFile}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading={<Spin size="large" />}
                >
                  <Page
                    pageNumber={pageNumber}
                    scale={scale}
                    renderTextLayer={false}
                  />
                </Document>
                <p className="text-center mt-4">
                  {t("requestDetails.page")} {pageNumber}{" "}
                  {t("requestDetails.of")} {numPages}
                </p>
              </div>
            )}
          </div>
        )}
      </Col>
    </Row>
  );
}

export default RequestDetails;
