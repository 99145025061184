// src/features/counter/counterSlice.js
import { createSlice } from "@reduxjs/toolkit";

const counterSlice = createSlice({
  name: "counter",
  initialState: {
    value: 0,
  },
  reducers: {
    increment(state) {
      // Return a new state object with the incremented value
      return {
        ...state,
        value: state.value + 1,
      };
    },
    decrement(state) {
      // Return a new state object with the decremented value
      return {
        ...state,
        value: state.value - 1,
      };
    },
    // You can define other actions here
  },
});

export const { increment, decrement } = counterSlice.actions;
export default counterSlice.reducer;
