import React, { useState } from "react";
import { Form, Input, Button, Typography, Row, Col, notification } from "antd";
import { useTranslation } from "react-i18next";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const recaptchaRef = React.createRef();

  const handleSubmit = async (values) => {
    if (!captchaValid) {
      notification.error({
        message: t("error.captcha"),
        description: t("error.captcha_desc"),
      });
      return;
    }

    // Email validation regex pattern
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Check if the email is in valid format
    if (!emailPattern.test(values.email)) {
      notification.error({
        message: t("error.email"),
        description: t("error.email_desc"),
      });
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/service/request`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            language: "all",
          },
          body: JSON.stringify({
            serviceId: 1,
            name: values.name,
            email: values.email,
            message: values.message,
          }),
        }
      );

      // Read the response body as JSON
      const responseBody = await response.json();
      if (!responseBody.success) {
        throw new Error("Server Error");
      }

      form.resetFields();
      setCaptchaValue(null); // Reset CAPTCHA value state
      setCaptchaValid(false); // Reset CAPTCHA valid state
      recaptchaRef.current.reset(); // Reset CAPTCHA widget

      notification.success({
        message: t("contact.successMessage"),
      });
    } catch (error) {
      notification.error({
        message: t("error.submit"),
        description: t("error.submit_desc"),
      });
    }
  };

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaValid(!!value); // Update CAPTCHA valid state based on the presence of a value
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={22} md={16} lg={12}>
        <div className="contact-content">
          <Typography.Title level={2}>{t("contact.contact")}</Typography.Title>
          <Typography.Paragraph>{t("contact.happy")}</Typography.Paragraph>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label={t("contact.name")}
              name="name"
              rules={[{ required: true, message: t("contact.nameError") }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("contact.email")}
              name="email"
              rules={[
                { required: true, message: t("contact.emailError") },
                {
                  type: "email",
                  message: t("contact.emailInvalid"),
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t("contact.message")}
              name="message"
              rules={[{ required: true, message: t("contact.messageError") }]}
            >
              <Input.TextArea rows={4} />
            </Form.Item>
            <Form.Item>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("contact.send")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Contact;
