// features/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import counterReducer from "./features/counter";
import userReducer from "./features/userSlice";
import { authApi } from "./features/authSlice";
import { requestService } from "./features/requestSlice";
import { configurationApi } from "./features/configurationsSlice";
import { notificationsService } from "./features/notificationsSlice";
import { userApi } from "./features/userApi";

// Combine all the reducers
const appReducer = combineReducers({
  counter: counterReducer,
  authApi: authApi.reducer,
  [requestService.reducerPath]: requestService.reducer,
  [configurationApi.reducerPath]: configurationApi.reducer,
  [notificationsService.reducerPath]: notificationsService.reducer,
  [userApi.reducerPath]: userApi.reducer,
  user: userReducer,
});

// Root reducer to handle reset state action
const rootReducer = (state, action) => {
  if (action.type === "resetState") {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
