import React, { useEffect, useState } from "react";
import { Row, Col, Typography, Form, Input, Button, notification } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useLoginUserMutation } from "./../features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "../features/userSlice";
import i18n from "i18next";

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const user = useSelector((state) => state.user);
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.user?.id) navigate(`/${i18n.language}`);
  }, [user, navigate]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const openNotification = (type, message) => {
    notification[type]({
      message,
      duration: 2,
    });
  };

  const handleSubmit = async (values) => {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(values.email)) {
      openNotification("error", "Invalid email format");
      return;
    }
    try {
      const response = await loginUser({
        email: values.email,
        password: values.password,
      }).unwrap();

      if (response.success) {
        dispatch(setUser({ user: response.user, token: response.token }));
        openNotification("success", "Login successful!");
        setTimeout(() => {
          navigate("/request");
        }, 2000);
      } else {
        openNotification("error", response.message);
      }
    } catch (error) {
      openNotification("error", "Invalid email or password");
    }
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={22} md={16} lg={12}>
        <div className="login-content">
          <Typography.Title level={2}>{t("login.login")}</Typography.Title>
          <Form layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item
              label={t("login.Password")}
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {isLoading ? t("login.loggingIn") : t("login.login")}
              </Button>
              <Button type="link" onClick={() => navigate("/forgot-password")}>
                {t("login.forgotPassword")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default Login;
