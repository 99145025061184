import React from "react";
import { Typography, Row, Col, Spin } from "antd";
import { Bar, Pie } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";
import { useGetRequestStatisticsQuery } from "../features/requestSlice"; // Adjust the path accordingly
import { useTranslation } from "react-i18next";

// Register the components
Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ArcElement,
  Tooltip,
  Legend
);

const RequestCharts = () => {
  const { t } = useTranslation();
  const {
    data: statistics,
    isLoading,
    isError,
  } = useGetRequestStatisticsQuery();

  const requestData = {
    labels: [t("charts.open"), t("charts.inProgress"), t("charts.closed")],
    datasets: [
      {
        label: t("charts.numberOfRequests"),
        data: [
          statistics?.statistic?.new || 0,
          statistics?.statistic?.scanning || 0,
          statistics?.statistic?.closed || 0,
        ],
        backgroundColor: ["#007bff", "#ffc107", "#28a745"],
      },
    ],
  };
  const statusData = {
    labels: [t("charts.open"), t("charts.inProgress"), t("charts.closed")],
    datasets: [
      {
        data: [
          statistics?.statistic?.new || 0,
          statistics?.statistic?.scanning || 0,
          statistics?.statistic?.closed || 0,
        ],
        backgroundColor: ["#007bff", "#ffc107", "#28a745"],
      },
    ],
  };

  if (isLoading) return <Spin />;

  if (isError) return <div>{t("charts.errorFetchingStatistics")}</div>;

  return (
    <div>
      <Typography.Title level={3}>
        {t("charts.requestStatistics")}
      </Typography.Title>
      <Row gutter={[16, 16]} justify="center">
        <Col
          xs={24}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", height: "400px" }}>
            <Typography.Title level={4}>
              {t("charts.numberOfRequests")}
            </Typography.Title>
            <Bar data={requestData} options={{ maintainAspectRatio: false }} />
          </div>
        </Col>
        <Col
          xs={24}
          md={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ width: "100%", height: "400px" }}>
            <Typography.Title level={4}>
              {t("charts.requestStatus")}
            </Typography.Title>
            <Pie data={statusData} options={{ maintainAspectRatio: false }} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default RequestCharts;
