import React, { useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Tooltip,
  Typography,
  Row,
  Col,
  notification,
} from "antd";
import {
  useUpdatePasswordMutation,
  useUpdateUserMutation,
} from "../features/userApi"; // Update the import path accordingly
import { useSelector } from "react-redux";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useTranslation } from "react-i18next";

const CombinedProfile = () => {
  const { t } = useTranslation();
  const [
    updatePassword,
    { isLoading: isPasswordLoading, isError: isPasswordError },
  ] = useUpdatePasswordMutation();
  const [updateUser, { isLoading: isUserLoading, isError: isUserError }] =
    useUpdateUserMutation();
  const [profileForm] = Form.useForm();
  const [passwordForm] = Form.useForm();
  const user = useSelector((state) => state.user.user);

  useEffect(() => {
    if (user) {
      profileForm.setFieldsValue({
        email: user.email,
        name: user.userName,
        role: user.groups?.map((group) => group.name).join(","),
        phone: user.phoneNumber,
      });
    }
  }, [user, profileForm]);

  const handleProfileSubmit = async (values) => {
    try {
      const result = await updateUser({
        id: user.id,
        name: values.name,
        phone: values.phone,
      }).unwrap();

      notification.success({
        message: t("error.profileUpdated"),
        description: t("error.profileUpdatedDescription"),
      });
    } catch (error) {
      notification.error({
        message: t("error.profileUpdateFailed"),
        description: t("error.profileUpdateFailedDescription"),
      });
    }
  };

  const handlePasswordSubmit = async (values) => {
    if (values.newPassword !== values.confirmPassword) {
      notification.error({
        message: t("error.passwordsDoNotMatch"),
        description: t("error.passwordsDoNotMatchDescription"),
      });
      return;
    }

    const validationError = validatePassword(values.newPassword, t);

    if (validationError) {
      notification.error({
        message: t("error.passwordChangeFailed"),
        description: validationError,
      });
      return;
    }

    try {
      const result = await updatePassword({
        oldPassword: values.currentPassword,
        newPassword: values.newPassword,
      }).unwrap();

      passwordForm.resetFields();
      if (result?.success) {
        notification.success({
          message: t("error.passwordChanged"),
          description: t("error.passwordChangedDescription"),
        });
      } else {
        notification.error({
          message: t("error.passwordChangeFailed"),
          description: result.message,
        });
      }
    } catch (error) {
      notification.error({
        message: t("error.passwordChangeFailed"),
        description: t("error.passwordChangeFailedDescription"),
      });
    }
  };

  return (
    <div>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} md={12}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Typography.Title level={3}>
              {t("profile.profileInformation")}
            </Typography.Title>
            <Form
              form={profileForm}
              layout="vertical"
              onFinish={handleProfileSubmit}
              style={{ flexGrow: 1 }}
            >
              <Form.Item
                label={t("profile.email")}
                name="email"
                rules={[
                  { required: true, message: t("profile.requiredField") },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={t("profile.name")}
                name="name"
                rules={[
                  { required: true, message: t("profile.requiredField") },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t("profile.roles")}
                name="role"
                rules={[
                  { required: true, message: t("profile.requiredField") },
                ]}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label={t("profile.phoneNumber")}
                name="phone"
                rules={[
                  {
                    required: true,
                    message: t("profile.inputPhoneNumber"),
                  },
                  {
                    pattern: /^\+?[1-9]\d{1,14}$/,
                    message: t("profile.validPhoneNumber"),
                  },
                ]}
              >
                <PhoneInput
                  country={"us"}
                  inputStyle={{ width: "100%" }}
                  placeholder={t("profile.phoneNumber")}
                />
              </Form.Item>
              <div style={{ marginTop: "auto", textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isUserLoading}
                >
                  {t("profile.updateProfile")}
                </Button>
              </div>
              {isUserError && (
                <Typography.Text type="danger">
                  {t("profile.errorFailedUpdateProfile")}
                </Typography.Text>
              )}
            </Form>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div
            style={{ display: "flex", flexDirection: "column", height: "100%" }}
          >
            <Typography.Title level={3}>
              {t("profile.changePassword")}
            </Typography.Title>
            <Form
              form={passwordForm}
              layout="vertical"
              onFinish={handlePasswordSubmit}
              style={{ flexGrow: 1 }}
            >
              <Form.Item
                label={t("profile.currentPassword")}
                name="currentPassword"
                rules={[
                  { required: true, message: t("profile.requiredField") },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item
                label={t("profile.newPassword")}
                name="newPassword"
                rules={[
                  { required: true, message: t("profile.requiredField") },
                ]}
                extra={t("profile.passwordStandardHint")}
              >
                <Input.Password
                  placeholder={t("profile.passwordPlaceholder")}
                />
              </Form.Item>
              <Form.Item
                label={t("profile.confirmPassword")}
                name="confirmPassword"
                rules={[
                  { required: true, message: t("profile.requiredField") },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <div style={{ marginTop: "auto", textAlign: "center" }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={isPasswordLoading}
                >
                  {t("profile.changePasswordButton")}
                </Button>
              </div>
              {isPasswordError && (
                <Typography.Text type="danger">
                  {t("profile.errorFailedChangePassword")}
                </Typography.Text>
              )}
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default CombinedProfile;

function validatePassword(password, t) {
  const minLength = 8;
  const hasUppercase = /[A-Z]/.test(password);
  const hasLowercase = /[a-z]/.test(password);
  const hasNumber = /\d/.test(password);
  const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

  if (password.length < minLength) {
    return t("error.tooShort");
  }
  if (!hasUppercase) {
    return t("error.noUppercase");
  }
  if (!hasLowercase) {
    return t("error.noLowercase");
  }
  if (!hasNumber) {
    return t("error.noNumber");
  }
  if (!hasSpecialChar) {
    return t("error.noSpecialChar");
  }

  return null;
}
