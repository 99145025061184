import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.user?.token;
    if (token) {
      headers.set("token", `${token}`);
    }
    headers.set("Content-Type", "application/json");
    return headers;
  },
});

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery,
  endpoints: (builder) => ({
    updatePassword: builder.mutation({
      query: ({ oldPassword, newPassword }) => ({
        url: "users/password/update",
        method: "POST",
        body: { oldPassword, newPassword },
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, name, phone }) => ({
        url: `users/${id}/update`,
        method: "POST",
        body: { userName: name, phoneNumber: phone },
      }),
    }),
  }),
});

export const { useUpdatePasswordMutation, useUpdateUserMutation } = userApi;
