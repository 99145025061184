import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

// Define your base query function
const baseQuery = fetchBaseQuery({ baseUrl: process.env.REACT_APP_API_URL });

// Define your API slice using createApi
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery,
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: ({ email, password }) => ({
        url: "users/verifiy",
        method: "POST",
        body: { email, password },
      }),
      transformResponse: (response) => response,
    }),
    forgetPassword: builder.mutation({
      query: ({ email }) => ({
        url: "users/password/forget",
        method: "POST",
        body: { email },
      }),
      transformResponse: (response) => response,
    }),
    resetPassword: builder.mutation({
      query: ({ email, passwordResetingCode, password }) => ({
        url: "users/password/reset",
        method: "POST",
        body: { email, passwordResetingCode, password },
      }),
      transformResponse: (response) => response,
    }),
  }),
});

// Export the endpoint functions
export const {
  useLoginUserMutation,
  useForgetPasswordMutation,
  useResetPasswordMutation,
  util,
  enhanceEndpoints,
  internalActions,
} = authApi;

export const { endpoints, reducerPath, reducer, middleware } = authApi;
