import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Table, Badge, Checkbox, Tooltip } from "antd";
import moment from "moment";
import {
  useCreateRequestMutation,
  useGetMyRequestsQuery,
} from "../features/requestSlice";
import { useNavigate } from "react-router-dom";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import "../index.css"; // Import the Tailwind CSS file

const RequestPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user?.user?.id) navigate(`/${i18n.language}/login`);
  }, [user, navigate]);

  const { data: requestsData, error, isLoading } = useGetMyRequestsQuery();
  if (isLoading) return <div>{t("requestsPage.loading")}</div>;
  if (error)
    return (
      <div>
        {t("requestsPage.error")}: {error.message}
      </div>
    );

  const fileColumns = [
    { title: t("requestsPage.fileId"), dataIndex: "id", key: "id" },
    {
      title: t("requestsPage.originalName"),
      dataIndex: "originalname",
      key: "originalname",
    },
    {
      title: t("requestsPage.mimeType"),
      dataIndex: "mimetype",
      key: "mimetype",
    },
    { title: t("requestsPage.size"), dataIndex: "size", key: "size" },
    {
      title: t("requestsPage.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
    {
      title: t("requestsPage.extension"),
      dataIndex: "extension",
      key: "extension",
    },
    {
      title: t("requestsPage.status"),
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        // Handle different cases for aiResult
        let tooltipText;
        let isChecked;

        if (record.aiResult === null) {
          // Case when not scanned (aiResult is null)
          tooltipText = t("requestsPage.notScanned");
          isChecked = false;
        } else if (record.aiResult === 0) {
          // Case when scanned but result is 0
          tooltipText = t("requestsPage.scanned");
          isChecked = true;
        } else {
          // Case when scanned with a positive result
          tooltipText = t("requestsPage.scanned");
          isChecked = true;
        }

        return (
          <Tooltip title={tooltipText}>
            <Checkbox checked={isChecked} disabled />
          </Tooltip>
        );
      },
    },
    {
      title: t("requestsPage.scanResult"),
      dataIndex: "aiResult",
      key: "aiResult",
      render: (text) => {
        if (text === null) {
          return t("requestsPage.notScanned"); // For not scanned cases
        } else if (text === 0) {
          return `0%`; // For scanned but 0 result
        } else {
          return `${text}%`; // For scanned with positive result
        }
      },
    },
  ];

  const expandedRowRender = (record) => {
    return (
      <>
        <Table
          columns={fileColumns}
          dataSource={record.files}
          pagination={false}
          rowKey="id"
        />
      </>
    );
  };

  const columns = [
    { title: t("requestsPage.id"), dataIndex: "id", key: "id" },
    { title: t("requestsPage.name"), dataIndex: "name", key: "name" },
    { title: t("requestsPage.email"), dataIndex: "Email", key: "Email" },
    {
      title: t("requestsPage.status"),
      dataIndex: "status",
      key: "status",
      render: (text) => <Badge status="success" text={text} />,
    },
    // { title: t("requestsPage.message"), dataIndex: "message", key: "message" },
    {
      title: t("requestsPage.serviceId"),
      dataIndex: "serviceId",
      key: "serviceId",
    },
    {
      title: t("requestsPage.createdAt"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (text) => moment(text).format("YYYY-MM-DD HH:mm"),
    },
  ];

  return (
    <Row justify="center" align="middle">
      <Col xs={22} md={20} lg={18}>
        <Table
          className="table-auto w-full"
          columns={columns}
          expandable={{
            expandedRowRender,
          }}
          dataSource={requestsData.serviceRequests}
          rowKey="id"
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/${i18n.language}/request/${record.id}`);
              },
            };
          }}
          rowClassName={(record, index) =>
            index % 2 === 0 ? "bg-blue-200" : "bg-blue-100"
          }
        />
      </Col>
    </Row>
  );
};

export default RequestPage;
