import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Upload,
  notification,
  Spin,
} from "antd";
import i18n from "i18next";
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useFetchConfigurationQuery } from "../features/configurationsSlice";
import { useCreateRequestMutation } from "../features/requestSlice";
import ReCAPTCHA from "react-google-recaptcha";
const { Dragger } = Upload;

const RequestPage = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const { t } = useTranslation();
  const { data: config, isLoading } = useFetchConfigurationQuery();
  const [aiFileConfig, setFileConfig] = useState(null);
  const [name, setName] = useState("");
  const [message, setMessage] = useState("");
  const [files, setFiles] = useState([]);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [captchaValid, setCaptchaValid] = useState(false);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    if (config) {
      const fileConfig = config?.configurations?.find(
        (config) => config.key === "FILE_CONFIG"
      );
      if (fileConfig) {
        setFileConfig(JSON.parse(fileConfig.value)["ai-files"]);
      }
    }
  }, [config]);

  useEffect(() => {
    if (!user?.user?.id) navigate(`/${i18n.language}/login`);
  }, [user, navigate]);

  const [createRequest] = useCreateRequestMutation();

  const handleFileChange = (info) => {
    const validFiles = info.fileList.map((file) => {
      if (file.originFileObj instanceof File) {
        return file.originFileObj;
      }
      return file;
    });

    setFiles(validFiles);
  };

  const handleSubmit = async () => {
    if (!files.length) {
      notification.error({
        message: t("error.file"),
        description: t("error.file_desc"),
      });
      return;
    }

    if (!captchaValid) {
      notification.error({
        message: t("error.captcha"),
        description: t("error.captcha_desc"),
      });
      return;
    }

    try {
      await createRequest({
        name,
        email: user?.user?.email,
        files,
        message,
      }).unwrap();
      notification.success({
        message: t("requestPage.success"),
      });

      // Clear form fields
      setName("");
      setMessage("");
      setFiles([]);
      form.resetFields();
      recaptchaRef.current.reset();
      setCaptchaValue(null);
      setCaptchaValid(false);
      navigate("/en/request/mine");
    } catch (error) {
      console.error({ message: error.message });
      notification.error({
        message: t("error.submit"),
        description: t("error.submit_desc"),
      });
    }
  };

  const fileHints = aiFileConfig
    ? t("requestPage.fileHints", {
        maxSize: Math.floor(aiFileConfig.maxSize / (1024 * 1024)) + " MB",
        types: aiFileConfig["allowed-type"].join(", "),
        maxCount: 1,
      })
    : "";

  const validateFile = (file) => {
    const fileType = file.name.split(".").pop();

    const isValidType = aiFileConfig["allowed-type"].includes("." + fileType);
    const isValidSize = file.size <= aiFileConfig.maxSize;

    if (!isValidType) {
      notification.error({
        message: t("requestPage.fileTypeErrorTitle"),
        description: t("requestPage.fileTypeErrorDescription", {
          types: aiFileConfig["allowed-type"].join(", "),
        }),
      });
    }

    if (!isValidSize) {
      notification.error({
        message: t("requestPage.fileSizeErrorTitle"),
        description: t("requestPage.fileSizeErrorDescription", {
          maxSize: Math.floor(aiFileConfig.maxSize / (1024 * 1024)) + " MB",
        }),
      });
    }

    return isValidType && isValidSize;
  };

  if (isLoading || !aiFileConfig) {
    return <Spin tip={t("requestPage.loading")} />;
  }

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setCaptchaValid(!!value);
  };

  return (
    <Row justify="center" align="middle">
      <Col xs={22} md={16} lg={12}>
        <div>
          <Form layout="vertical" onFinish={handleSubmit} form={form}>
            <Form.Item
              label={t("requestPage.fileName")}
              name="name"
              rules={[{ required: true, message: t("error.nameRequired") }]}
            >
              <Input value={name} onChange={(e) => setName(e.target.value)} />
            </Form.Item>
            <Form.Item label={t("requestPage.message")} name="message">
              <Input.TextArea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={t("requestPage.uploadFile")}
              extra={fileHints}
              rules={[{ required: true, message: t("error.fileRequired") }]}
            >
              <Dragger
                name="file"
                customRequest={({ file, onSuccess }) =>
                  setTimeout(() => onSuccess("ok"), 0)
                }
                onChange={handleFileChange}
                beforeUpload={(file) => {
                  return validateFile(file) ? true : Upload.LIST_IGNORE;
                }}
                maxCount={1}
                accept={
                  aiFileConfig["allowed-type"]
                    ? aiFileConfig["allowed-type"].join(",")
                    : ""
                }
                fileList={files}
              >
                <p className="ant-upload-drag-icon">
                  <i className="anticon anticon-upload"></i>
                </p>
                <p className="ant-upload-text">
                  {t("requestPage.fileComponent")}
                </p>
              </Dragger>
            </Form.Item>
            <Form.Item>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={handleCaptchaChange}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                {t("requestPage.submit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Col>
    </Row>
  );
};

export default RequestPage;
