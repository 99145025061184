import React from "react";
import { Row, Col, Typography } from "antd";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const Home = () => {
  const { t } = useTranslation();

  return (
    <Row justify="center" align="middle">
      <Col xs={22} md={16} lg={12}>
        <div className="home-content">
          <Title level={2} gutterBottom>
            {t("home.aboutUsTitle")}
          </Title>
          <Paragraph>{t("home.aboutUsText")}</Paragraph>
          <Title level={3} gutterBottom>
            {t("home.capabilities")}
          </Title>
          <ul className="list-none pl-0">
            <li className="relative pl-4 before:content-['-'] before:absolute before:left-0">
              {t("home.sentence_1")}
            </li>
            <li className="relative pl-4 before:content-['-'] before:absolute before:left-0">
              {t("home.sentence_2")}
            </li>
            <li className="relative pl-4 before:content-['-'] before:absolute before:left-0">
              {t("home.sentence_3")}
            </li>
            <li className="relative pl-4 before:content-['-'] before:absolute before:left-0">
              {t("home.sentence_4")}
            </li>
            <li className="relative pl-4 before:content-['-'] before:absolute before:left-0">
              {t("home.sentence_5")}
            </li>
          </ul>
          <Paragraph className="text-lg font-semibold text-center mt-6">
            {t("home.end")}
          </Paragraph>
        </div>
      </Col>
    </Row>
  );
};

export default Home;
