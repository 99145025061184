import React, { useEffect, useState } from "react";
import { Select, Button, Badge, Dropdown, Menu } from "antd";
import { LoginOutlined, LogoutOutlined, BellOutlined } from "@ant-design/icons";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { clearUser } from "../features/userSlice";
import {
  useGetNotificationsQuery,
  useMarkNotificationsMutation,
} from "../features/notificationsSlice";
import { getLanguage } from "../helper/language";

const { Option } = Select;

const AppMenu = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [notificationVisible, setNotificationVisible] = useState(false);

  const {
    data: notifications = [],
    isLoading,
    isError,
    refetch,
  } = useGetNotificationsQuery();

  useEffect(() => {
    if (isLoading) {
      console.log("Loading notifications...");
    }
    if (isError) {
      console.error("Failed to fetch notifications.");
    }
  }, [isLoading, isError]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 30000); // 30 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [refetch]);

  const user = useSelector((state) => state.user.user);
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("lng", lng);
    const currentPath = location.pathname.substring(3);
    navigate(`/${lng}${currentPath}`);
    localStorage.setItem("language", lng);
  };
  const handleLogout = () => {
    dispatch(clearUser()); // Clears user state
    dispatch({ type: "resetState" }); // Resets all states
    navigate(`/${i18n.language}/login`); // Redirect to login page
  };
  const handleLogIn = () => {
    navigate(`/${i18n.language}/login`); // Redirect to login page
  };

  const [markNotifications] = useMarkNotificationsMutation();

  const handleDropdownVisibleChange = (visible) => {
    setNotificationVisible(visible);
    if (visible) {
      refetch(); // Refetch notifications when the menu is opened
      const ids = notifications?.notifications?.map(
        (notification) => notification.id
      );

      if (ids?.length) {
        markNotifications(ids);
      }
    }
  };

  const lang = getLanguage(i18n.language);
  const notificationMenu = (
    <Menu>
      {notifications?.notifications?.length === 0 ? (
        <Menu.Item>{t("noNotifications")}</Menu.Item>
      ) : (
        notifications?.notifications?.map((notification, index) => (
          <Menu.Item
            key={index}
            onClick={() => handleNotificationClick(notification.requestId)}
            style={{
              cursor: "pointer",
            }}
          >
            {notification?.message?.[lang]}
          </Menu.Item>
        ))
      )}
    </Menu>
  );
  const handleNotificationClick = (requestId) => {
    navigate(`/${i18n.language}/request/${requestId}`);
  };

  return (
    <nav className="flex justify-between items-center px-4 py-2 bg-gray-100 shadow">
      <div className="flex-1 flex justify-start" />
      <div className="flex-1 flex justify-center">
        <NavLink
          className="mx-2 text-lg"
          to={`/${i18n.language}`}
          exact
          activeClassName="activeLink"
        >
          {t("homeMenu")}
        </NavLink>
        <NavLink
          className="mx-2 text-lg"
          to={`/${i18n.language}/contact`}
          activeClassName="activeLink"
        >
          {t("contactMenu")}
        </NavLink>
        {user && (
          <>
            <NavLink
              className="mx-2 text-lg"
              to={`/${i18n.language}/request`}
              activeClassName="activeLink"
            >
              {t("addRequest")}
            </NavLink>
            <NavLink
              className="mx-2 text-lg"
              to={`/${i18n.language}/request/mine`}
              activeClassName="activeLink"
            >
              {t("myRequests")}
            </NavLink>
            <NavLink
              className="mx-2 text-lg"
              to={`/${i18n.language}/profile`}
              activeClassName="activeLink"
            >
              {/* //{t("myRequests")} */}
              {t("profileMenu")}
            </NavLink>
          </>
        )}
      </div>
      <div className="flex-1 flex justify-end items-center space-x-6">
        <Select
          value={i18n.language}
          onChange={changeLanguage}
          size="small"
          className="w-16"
        >
          <Option value="en">EN</Option>
          <Option value="ru">RU</Option>
        </Select>
        <Dropdown
          overlay={notificationMenu}
          visible={notificationVisible}
          onVisibleChange={handleDropdownVisibleChange}
        >
          <Badge
            count={
              notifications?.notifications?.filter(
                (notification) => !notification.seenByUSer
              ).length || 0
            }
          >
            <BellOutlined className="text-xl text-blue-500 cursor-pointer" />
          </Badge>
        </Dropdown>
        {user ? (
          <Button
            type="primary"
            icon={<LogoutOutlined />}
            onClick={handleLogout}
            className="flex items-center justify-center bg-red-500"
          />
        ) : (
          <Button
            type="primary"
            icon={<LoginOutlined />}
            onClick={handleLogIn}
            className="flex items-center justify-center bg-green-500"
          />
        )}
      </div>
    </nav>
  );
};

export default AppMenu;
