import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_URL,
  prepareHeaders: (headers, { getState }) => {
    const token = getState()?.user?.token;
    if (token) {
      headers.set("token", `${token}`);
    }
    return headers;
  },
});

export const notificationsService = createApi({
  reducerPath: "notificationsService",
  baseQuery,
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: () => ({
        url: "/notification/mine",
        method: "GET",
      }),
      transformResponse: (response) => response,
      providesTags: ["Notification"],
    }),
    markNotifications: builder.mutation({
      query: (ids) => ({
        url: "/notification/mark/mine",
        method: "POST",
        body: { ids },
      }),
      invalidatesTags: ["Notification"],
    }),
  }),
});

export const { useGetNotificationsQuery, useMarkNotificationsMutation } =
  notificationsService;
