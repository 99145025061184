// app/store.js
import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import rootReducer from "./rootReducer"; // Import the root reducer
import { authApi } from "./features/authSlice";
import { requestService } from "./features/requestSlice";
import { configurationApi } from "./features/configurationsSlice";
import { notificationsService } from "./features/notificationsSlice";
import { userApi } from "./features/userApi";

// Function to load state from localStorage
const loadState = () => {
  try {
    const user = localStorage.getItem("user");
    const token = localStorage.getItem("token");
    if (user === null || token === null) {
      return undefined;
    }
    return { user: { user: JSON.parse(user), token: JSON.parse(token) } };
  } catch (err) {
    return undefined;
  }
};

const preloadedState = loadState();
const store = configureStore({
  reducer: rootReducer,
  preloadedState, // Use preloadedState
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(authApi.middleware) // Add the middleware for RTK-Query API
      .concat(requestService.middleware)
      .concat(configurationApi.middleware) // Add the middleware for RTK-Query API
      .concat(notificationsService.middleware) // Add the middleware for RTK-Query API
      .concat(userApi.middleware), // Add the middleware for RTK-Query API
});

setupListeners(store.dispatch); // Set up listeners for cache invalidation and refetching

export default store;
