import React from "react";
import { Row, Col, Image, Divider } from "antd";
import CombinedProfile from "./../components/CombinedProfile";
import RequestCharts from "./../components/RequestCharts";

const Profile = () => {
  return (
    <div style={{ padding: "20px" }}>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col xs={24} md={8} lg={6} style={{ textAlign: "center" }}>
          <Image
            width={200}
            src="https://via.placeholder.com/150"
            alt="Profile Picture"
            style={{ borderRadius: "50%" }}
          />
        </Col>
        <Col xs={24} md={16} lg={18}>
          <CombinedProfile />
        </Col>
      </Row>

      <Divider style={{ margin: "40px 0" }} />

      <Row gutter={[16, 16]} justify="center">
        <Col xs={24}>
          <RequestCharts />
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
