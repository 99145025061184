import React from "react";
import { useFetchConfigurationQuery } from "../features/configurationsSlice";

const ConfigurationComponent = () => {
  const {
    data: configuration,
    error,
    isLoading,
  } = useFetchConfigurationQuery();
  if (configuration) console.log({ configuration });

  if (error) return <div>Error loading configuration</div>;

  return <></>;
};

export default ConfigurationComponent;
