export const uploadFile = async (file) => {
  const formData = new FormData();
  formData.append("files", file);

  const response = await fetch(
    `${process.env.REACT_APP_API_URL}/file/upload?key=ai-files`,
    {
      method: "POST",
      body: formData,
    }
  );

  if (!response.ok) {
    throw new Error("File upload failed");
  }

  const data = await response.json();
  return data; // Assuming the response contains the metadata for the uploaded file
};

export const handleFiles = async (files, description, forScan, title) => {
  const uploadedFiles = [];
  await Promise.all(
    files.map(async (file) => {
      const fileData = await uploadFile(file);
      const fileMetadata = {
        uuid: fileData.files[0].filename,
        description: { ar: "", en: description },
        title: { ar: "", en: title },
        size: fileData.files[0].size,
        extension: fileData.files[0].extension,
        mimetype: fileData.files[0].mimetype,
        originalname: fileData.files[0].originalname,
      };
      uploadedFiles.push(fileMetadata);
      // Assuming the response contains an 'id' field with the file ID
    })
  );
  const response = await fetch(`${process.env.REACT_APP_API_URL}/file`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Forscan: true,
    },
    body: JSON.stringify(uploadedFiles),
  });

  if (!response.ok) {
    throw new Error("Adding file to database failed");
  }

  const data = await response.json();
  return data;
};
