import React from "react";
import { useTranslation } from "react-i18next";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import Login from "./pages/Login";
import RequestPage from "./pages/Request";
import MyRequests from "./pages/Requests";
import ForgotPassword from "./pages/ForgotPassword";
import store from "./store";

// Action creators
import TranslationProvider from "./TransaltionProvider";
import Menu from "./components/Menu";
import ConfigurationComponent from "./components/ConfigurationComponsnt";
import RequestDetails from "./pages/RequestDetails";
import Profile from "./pages/Profile";
function App() {
  const { t } = useTranslation();

  return (
    <Provider store={store}>
      <Router>
        <TranslationProvider>
          <ConfigurationComponent></ConfigurationComponent>
          <div className="App">
            <header>
              <div>
                <h1>{t("welcomeMessage")}</h1>
              </div>
            </header>
            <Menu />
            <main>
              <Routes>
                <Route path="/:lng" element={<Home />} />
                <Route path="/:lng/contact" element={<Contact />} />
                <Route path="/:lng/login" element={<Login />} />
                <Route path="/:lng/request" element={<RequestPage />} />
                <Route path="/:lng/request/mine" element={<MyRequests />} />
                <Route
                  path="/:lng/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route path="/:lang/request/:id" element={<RequestDetails />} />
                <Route path="/:lang/profile" element={<Profile />} />
              </Routes>
            </main>
          </div>
        </TranslationProvider>
      </Router>
    </Provider>
  );
}

export default App;
